@use "mixins";

.tree-view-wrapper {
  position: relative;

  .tree-view-toggle {
    cursor: pointer;
    margin-bottom: 0;
    user-select: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .tree-view {
    list-style-type: none;
    position: absolute;
    background-color: white;
    border: 1px solid var(--bs-border-color);
    padding: 10px;
    width: 100%;
    height: 400px;
    overflow-y: scroll;
    border-radius: 5px;
    z-index: 10;

    & ul {
      list-style-type: none;
    }

    .toggle,
    .tree-view-label,
    .select-all,
    .select-none {
      cursor: pointer;
      user-select: none;
    }

    .toggle::before {
      @include mixins.fontAwesomeIcon('\f07b');
    }

    .toggle-active::before {
      @include mixins.fontAwesomeIcon('\f07c');
    }

    .select-all::before {
      @include mixins.fontAwesomeIcon('\f14a');
    }

    .select-none::before {
      @include mixins.fontAwesomeIcon('\f0c8', 400);
    }

    .children {
      display: none;
    }

    .active {
      display: block;
    }
  }
}
