// Customize bootstrap variables
$primary: #292826;
$secondary: #F9D342;

// Variables for price list rows
$suggestionColor: #d1fffd;
$declinedSuggestionColor: #ffb370;
$newTierPriceColor: #b0ffab;
$deleteTierPriceColor: #e5c7ca;

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import "~bootstrap/scss/bootstrap";
@import "navbar";
@import "tree-view";
// Place to override bootstraps root variable settings
:root,
[data-bs-theme="light"] {
  --bs-heading-color: $secondary;
}

@font-face {
  font-family: 'ROCK SHIELD';
  src: url('./fonts/rockshield.ttf') format('truetype');
}

html {
  position: relative;
  min-height: 100%;
}

body {
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-family: "ROCK SHIELD";
  color: $primary;
}

.main-container {
  height: 95.1vh;
  margin-top: 10px;
}

.cart-container {
  max-width: 95%;
}

.favorite-items {
  .image {
    white-space: nowrap;

    .image-helper {
      height: 80px;
      vertical-align: middle;
      display: inline-block;
    }

    img {
      vertical-align: middle;
      max-height: 75px;
    }
  }

  .information {
    width: 80%;

    .name {
      font-family: "ROCK SHIELD";
      font-weight: bold;
      display: block;
    }

    .sku {
      display: block;
      color: $primary;
    }

    .price {
      display: block;
      font-weight: bold;
      font-style: italic;
    }
  }
}

.tab-container {
  .buttons {
    button {
      font-size: 24px;
      height: 80px;
      margin-bottom: 20px;
    }
  }

  .tab-table {
    display: none;

    &.visible {
      display: block;
    }
  }
}


.cart-table {
  .row {
    margin: 10px 0;
    padding-bottom: 10px;
    border-bottom: 1px #aaa solid;

    &:first-child {
      margin-top: 50px;
    }

    &:last-child {
      border-bottom: none;
    }

    .sku {
      font-style: italic;
    }
  }
}

.original-price, .old-price, .discounted-price {
  float: right;
}

.old-price {
  margin-right: 10px;
  color: $danger;
  text-decoration: line-through;
}

.cart-totals {
  margin-top: 10px;
  border-top: 1px #aaa solid;
  padding-top: 10px;
}

.payment-button {
  font-family: "ROCK SHIELD";

  &.payment-margin-right {
    margin-right: 10px;
  }

  &#pay-cash {
    margin-right: 10px;
  }

  span {
    font-size: 26px;
  }
}

.cash-payment, .pin-payment, .payment-buttons-cancel, .payment-buttons-cash, .payment-buttons-pin {
  display: none;
}

.cash-payment, .pin-payment {
  margin-top: 10px;

  hr {
    margin-top: 10px;
  }
}

.payment-buttons-generic {
  display: flex;
}

.sku {
  color: #aaa;
}

#product-template {
  display: none;
}

.product-img {
  border: 1px solid #ccc;
  border-radius: 11px;
  width: 60px;
}

.qty-selector {
  background-color: #aaa;
  padding: 10px;
  border-radius: 10px;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 300px; /* Set the fixed height of the footer here */
  line-height: 30px; /* Vertically center the text there */
  background-color: #f5f5f5;
}

.alert {
  margin-top: 20px;
}

.column-item {
  text-align: center;
  padding: 10px;


  .column-item-link {
    &:hover {
      text-decoration: none;
    }
  }

  .connection-name {
    margin: 10px;
    font-size: 20px;
  }

  &:hover {
    border-radius: 5px;
    border: 1px solid $secondary;
  }
}

.color-icon-magento {
  color: #eb5202;
}

.color-icon-paypal {
  color: #00457C;
}

.label {
  color: white;
  padding: 8px;
  border-radius: 5px;

  &.success {
    background-color: #4CAF50;
  }

  /* Green */
  &.active {
    background-color: #4CAF50;
  }

  /* Green */
  &.info {
    background-color: #2196F3;
  }

  /* Blue */
  &.warning {
    background-color: #ff9800;
  }

  /* Orange */
  &.danger {
    background-color: #f44336;
  }

  /* Red */
  &.error {
    background-color: #f44336;
  }

  /* Red */
  &.other {
    background-color: #e7e7e7;
    color: black;
  }

  /* Gray */
  &.inactive {
    background-color: #e7e7e7;
    color: black;
  }

  /* Gray */
}

$green: #4CAF50;
$blue: #2196F3;
$orange: #ff9800;
$red: #f44336;
$grey: #666;
$light-grey: #e7e7e7;

.table-grid-view {
  td {
    padding: 10px 10px 0 10px;
    vertical-align: center;

    &:last-child {
      text-align: right;
    }
  }
}

.capacity-input {
  width: 50px;
}

.settings-input {
  width: 75px;
}

.settings-country-input {
  width: 300px;
}

.user-filter {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  display: flex;
  margin-bottom: 24px;
  padding-bottom: 24px;

  &__column {
    display: flex;
    flex-direction: column;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__input {
    display: flex;
    flex: 2;
  }

  &__locale {
    flex: 1;
    margin-right: 12px;
  }

  .table-search {
    border: 1px solid #aaa;
    border-radius: 4px;
    height: 100%;
    max-width: 500px;
    padding: 6px;
    width: 100%;

    &:focus,
    &:active {
      border-color: transparent;
      box-shadow: 0 0 0 3px rgba($blue, .75);
      outline: none;
    }
  }

  .table-search-reset {
    background: $grey;
    border: 1px solid $grey;
    border-radius: 4px;
    color: #fff;
    height: 100%;
    margin-left: 12px;
    padding: 6px 12px;

    &:hover {
      background: darken($grey, 15%);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: .3;

      &:hover {
        background: $grey;
      }
    }
  }
}

.clickable-row {
  cursor: pointer;

  td {
    cursor: pointer;
  }
}

.btns {
  display: flex;

  .btn {
    margin-left: 1rem;
    margin-right: 0 !important;

    &.btn-impersonate {
      background: #0dcaf0;
      border-color: #0dcaf0;
      color: #000;
    }
  }
}

button,
input[type='submit'],
input[type='button'],
input[type='reset'] {

  &.btn {
    opacity: 1;
    transition: opacity 300ms ease;

    &:disabled {
      cursor: not-allowed;
      pointer-events: auto;
      opacity: .4;
    }
  }
}

.suggestion-template {
  display: none;
}

.pricelist-table th {
  width: 30px;
  background-color: #EEE;
  border: 1px solid #FFF;
  border-bottom: 1px solid #000;
}

.table-row {
  &.new-row {
    background-color: $newTierPriceColor;

    .action-column {
      background-color: $newTierPriceColor;
    }
  }

  &.delete-row {
    background-color: $deleteTierPriceColor;

    .action-column {
      background-color: $deleteTierPriceColor;
    }
  }

  &.suggestion-row {
    background-color: $suggestionColor;

    .action-column {
      background-color: $suggestionColor;
    }
  }

  &.declined-row {
    background-color: $declinedSuggestionColor;

    .action-column {
      background-color: $declinedSuggestionColor;
    }
  }
}

.table > tbody > .price-suggestion-row > td:not(.action-column) {
  background: transparent;
}

.table > tbody > .tier-price-row > td:not(.action-column) {
  background: transparent;
}

.sticky-right {
  position: sticky;
  right: 0;
}

.sticky-left {
  position: sticky;
  left: 0;
}

.action-column {
  min-width: 150px;
  text-align: right;
}

.cursor-pointer {
  cursor: pointer;
}

.integration-icon {
  width: 30px;
  height: 32px;
}

.integration-icon-progress {
  filter: grayscale(100%);
}

.error-alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-width: none;
  z-index: 100;
}

.remove-error {
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
}

h1.pos-header {
  font-family: "ROCK SHIELD";
  margin: 0;
  font-size: 45px;
  color: #F9D342;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.print-receipt {
  .main-container {
    height: auto !important;
  }

  .cart-table {
    .row {
      border: none;
      margin: 0;
      padding: 0;

      &:first-child {
        margin-top: 10px !important;
      }

      .col-price {
        margin-top: 10px;
      }
    }
  }

  .receipt-address {
    span {
      display: block;

      &.name {
        font-family: "ROCK SHIELD";
        font-size: 15px;
      }

      &.info {
        font-size: 10px;
      }
    }
  }

  h5 {
    font-size: 10px;
  }

  .meta {
    font-style: italic;
  }

  .name {
    font-weight: bold;
    font-size: 10px;
    font-family: "ROCK SHIELD";
  }

  .sku {
    font-size: 10px;
  }

  .cart-totals {
    border: none;
  }
}

.report-totals {
  border-top: 2px solid #000;
  font-weight: bold;
}