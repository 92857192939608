@use "mixins";

.header {
  align-items: center;
  background: #EFEFEF;
  display: flex;
  justify-content: space-between;
  padding: 12px;
}

.nav {
  margin: 0;
  padding: 0;

  &__item {
    display: inline-block;
    list-style: none;
    position: relative;

    &-with-before {
      &::before {
        background: $primary;
        content: '';
        height: calc(100% - 32px);
        left: -1px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
      }
    }
    
    &-with-submenu {
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;

      &::before {
        background: $primary;
        content: '';
        height: calc(100% - 32px);
        left: -1px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
      }

      .nav__link {
        &::after {
          @include mixins.fontAwesomeIcon('\f107');
          display: inline-block;
          height: 10px;
          margin-left: 8px;
          width: 10px;
        }
      }
    }

    &:hover {
      .subnav {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__link {
    color: $primary;
    display: block;
    padding: 12px;
    text-decoration: none;

    &:hover {
      color: darken($secondary, 10%);
    }

    &--active {
      color: $secondary;
      text-decoration: underline;
      text-decoration-thickness: 4px;
      text-underline-offset: 10px;

      &:hover {
        color: darken($secondary, 10%);
      }
    }
  }

  .subnav {
    background: #FAFAFA;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, .14);
    border-radius: 4px;
    margin: 0;
    min-width: 175px;
    opacity: 0;
    padding: 0 16px;
    position: absolute;
    right: 0;
    transition: opacity 300ms ease, visibility 300ms ease;
    visibility: hidden;
    z-index: 10;

    &__item {
      list-style: none;

      + .subnav__item {
        .subnav__link {
          border-top: 1px solid #DFDFDF;
        }
      }
    }

    &__link {
      align-items: center;
      color: #6093D1;
      display: flex;
      padding: 12px 0;
      text-decoration: none;

      &:hover {
        color: darken($secondary, 10%);
      }

      &::before {
        display: inline-block;
        height: 20px;
        margin-right: 8px;
        width: 20px;
      }

      &--admin::before {
        @include mixins.fontAwesomeIcon('\f505');
      }

      &--profile::before {
        @include mixins.fontAwesomeIcon('\f4ff');
      }

      &--settings::before {
        @include mixins.fontAwesomeIcon('\f013');
      }

      &--logout::before {
        @include mixins.fontAwesomeIcon('\f023');
      }
    }
  }
}
